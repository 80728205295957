<template>
  <div class="home">
    <img src="https://weiapp.seimei.tech/website/seimei_web_index.jpg" class="bg" />
    <!-- <video class="bg" autoplay loop :muted="muted" ref="videoPlayer" src="https://weiapp.seimei.tech/activity_video/seimei_one_pro.mp4"></video> -->
    <img src="../assets/senmei-logo.png" class="logo" />
    <!-- <img src="../assets/muted-false.png" @click="mutedPlay(true)" v-if="!muted" class="muted-class" />
    <img src="../assets/muted-true.png" @click="mutedPlay(false)" v-else class="muted-class" /> -->
    <div class="center-content">
      <!-- <div style="height: 340px;width: 100%;"> -->
      <slider ref="silder" animation="normal" width="100%" :control-btn="false" height="28vw" @change="sliderChange"
        @before-next="sliderNext" v-model="current" :stop="stop">
        <slider-item v-for="(i, index) in language" :key="index">
          <div class="content">
            <div class="title" :class="index == 3 ? 'Regular' : ''">{{ i.title }}</div>
            <div class="title" :class="index == 3 ? 'Regular' : ''">{{ i.nextTitle }}</div>
            <div class="topic">
              <div class="li" :class="index == 3 ? 'Regular' : ''">{{ i.oneli }}</div>
              <div class="li-line" :class="index == 3 ? 'Regular' : ''"></div>
              <div class="li " :class="index == 3 ? 'Regular' : ''">{{ i.twoli }}</div>
            </div>
          </div>
        </slider-item>
      </slider>
      <!-- </div> -->

      <div class="langs">
        <!-- <div class="button-lan" :class="current == 0 ? 'active' : ''" @mouseenter="onEnterTd(0, 1)" @click="toLink(1)"
          @mouseleave="onLeaveTd(0)">
          简体中文网站
        </div> -->
        <div class="button-lan" :class="current == 1 ? 'active' : ''" @mouseenter="onEnterTd(1, 1)"
          @mouseleave="onLeaveTd(1)" style="margin-left:20px" @click="toLink(2)">
          繁体中文网站
        </div>
        <div class="button-lan" :class="current == 2 ? 'active' : ''" @mouseenter="onEnterTd(2, 1)"
          @mouseleave="onLeaveTd(2)" style="margin-left:20px" @click="toLink(3)">
          日本語HP
        </div>
        <div class="button-lan Regular" :class="current == 3 ? 'active' : ''" @mouseenter="onEnterTd(3, 1)"
          @mouseleave="onLeaveTd(3)" style="margin-left:20px" @click="toLink(4)">
          English Website
        </div>
      </div>
      <div class="el-login-footer">
        <!-- <div style="display: flex ;align-items: center;justify-content: center;flex-wrap: nowrap;">
          <div class="bottom">© SEIMEI 版权所有</div>
          <div class="line">|</div>
          <div class="bottom" @click="toLinkHttp" style="cursor: pointer;">京ICP备2021041040号-11</div>
          <div class="line">|</div>
          <div class="bottom" @click="toNav(1)" style="cursor: pointer;">隐私政策</div>
          <div class="line">|</div>
          <div class="bottom">联系我们 enquiry@seimei.com</div>
        </div> -->
        <div style="margin: 0 0!important;">
          ©SEIMEI All right reservd | Contact Us: enquiry@seimei.com
          <!--SEIMEI HOLDINGS (HONG KONG) LIMITED | © SEIMEI 版权所有 |
           <a href="https://beian.miit.gov.cn/" target="_blank"
            style="color: #FFFFFF;text-decoration: none;">京ICP备2021041040号-11</a>
          | -->
          <!-- <span style="cursor: pointer;" @click="toNav(1)">隐私政策</span>
          | 联系我们 enquiry@seimei.com-->
        </div>
      </div>
    </div>
    <Dialog v-show="isShow == true" @closelog="isShow = false"></Dialog>
  </div>
</template>

<script>
  import Dialog from '../components/Dialog.vue';
  import {
    Slider,
    SliderItem
  } from 'vue-easy-slider';
  export default {
    components: {
      Slider,
      SliderItem,
      Dialog
    },
    data() {
      return {
        stop: false,
        isShow: false,
        muted: true,
        current: 0,
        currents: 0,

        //语言数据
        language: [
          // {
          //   title: '以生物科技和人工智能',
          //   nextTitle: '与您一起探寻更健康的人生',
          //   oneli: '个性化抗衰产品',
          //   twoli: '数字化健康服务'
          // },
          {
            title: '以生物科技和人工智慧',
            nextTitle: '與您一起探尋更健康的人生',
            oneli: '個性化抗衰產品',
            twoli: '數位化健康服務'
          },
          {
            title: '生物テクノロジーと人工知能をもとに',
            nextTitle: '一緒により健康的な人生を',
            oneli: '個性化アンチエイジング製品',
            twoli: 'デジタル化健康サービス'
          },
          {
            title: 'Uncovering Healthier Life',
            nextTitle: 'With BioTech & AI for You',
            oneli: 'Personalized Anti-Aging Product',
            twoli: 'Health Digital Service'
          }
        ]
      };
    },
    // mounted() {
    //   this.play();
    // },
    methods: {
      playVideoMuted() {
        this.$nextTick(() => {
          this.$refs.videoPlayer.muted = false;
          this.$refs.videoPlayer.play();
        });
      },
      sliderNext(type) {
        console.log(111);
        let status = type;
        return status;
      },
      sliderChange(e) {
        this.current = e;
        // console.log(e);
        // this.onEnterTd(e, 0);
      },
      mutedPlay(boolean) {
        // console.log(boolean);
        this.muted = boolean;
        this.$refs.videoPlayer.muted = boolean;
      },
      toLink(type) {
        if (type == 1) {
          //简中
          window.location.href = 'https://cn.seimei.com/';
        } else if (type == 2) {
          //繁体
          window.location.href = 'https://shop.seimei.com/zh/';
        } else if (type == 3) {
          //日语
          window.location.href = 'https://shop.seimei.co.jp/';
        } else {
          //英语
          window.location.href = 'https://shop.seimei.com/';
        }
      },
      onEnterTd(current, type) {
        console.log(this.$refs.silder);
        this.current = current;
        // this.$refs.silder.getNextIndex();
        this.stop = true;
      },
      onLeaveTd(current) {
        this.autoplay = true;
        // this.$refs.silder.stopRemove();
        this.stop = false;
        // this.sliderNext(true);
        // this.current = -1;
      },
      toNav(type) {
        this.isShow = true;
        // this.$router.push({ path: '/privacy', query: { type: type } });
      },
      toLinkHttp() {
        window.location.href = 'https://beian.miit.gov.cn';
      },
      auto() {
        if (this.isStopped) return;

        if (this.timer) clearInterval(this.timer);
        this.timer = setInterval(() => {
          this.move(1);
        }, this.interval);
      },
      handleMouseenter() {
        return '进入';
        if (this.stopOnHover) {
          this.isStopped = true;
          if (this.timer) clearInterval(this.timer);
        }
      },
      handleMouseleave() {
        if (this.stopOnHover) {
          this.isStopped = false;
          this.auto();
        }
      }
    }
  };
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
  @media (max-width: 1920px) and (max-height: 580px) {
    .center-content {
      padding-top: 40vh;
    }
  }

  @media (max-width: 1024px) and (max-height: 580px) {
    .center-content {
      padding-top: 15vh;
    }
  }

  @media all and (max-height: 580px) {
    .home {
      position: absolute !important;
      min-height: 444px;
    }

    .logo {
      position: absolute !important;
      // overflow-y: scroll;
    }
  }

  @media all and (max-width: 992px) {
    .logo {
      width: 140px;
      left: 24px;
      top: 20px;
      // overflow-y: scroll;
    }
  }

  .home {
    width: 100%;
    height: 100vh;

    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
  }

  .bg {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    object-fit: fill;
  }

  .bg-one {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
    z-index: 9;
  }

  .logo {
    display: block;
    z-index: 999;
    width: 12.14vw;
    // height: 64px;
    position: fixed;
    left: 2vw;
    top: 1.5vw;
  }

  .muted-class {
    z-index: 99999;
    width: 50px;
    height: 50px;
    margin: 20px 30px;
    position: fixed;
    top: 30px;
    right: 40px;
  }

  .center-content {
    width: 100%;
    z-index: 999;
    // position: fixed;
    // top: 0;
    // right: 0;
    // left: 0;
    // bottom: 0;
    display: flex;
    justify-content: center;
    // align-items: center;
    flex-flow: column;
  }

  .langs {
    display: flex;
    align-items: center;
    margin-top: 37px;
    justify-content: center;
    width: 85%;
    margin: 37px auto;
    padding-bottom: 40px;
    // margin-bottom: 100px;
  }

  .content {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  .title {
    font-size: 3.64vw;
    font-weight: bold;
    color: #fff;
  }

  .topic {
    min-width: 37.8vw;
    background-image: url('../assets/pc.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 10px;
    // height: 2.91vw;
    // background: rgba(0, 111, 50, 0.54);
    display: flex;
    align-items: center;
    margin-top: 10px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .li {
    color: #fff;
    font-size: 1.56vw;
    font-weight: bold;
  }

  .li-line {
    width: 1px;
    height: 2.01vw;
    background-color: #fff;
    margin: 0 10px;
    color: #fff;
  }

  .margin-left {
    margin-left: 30px;
  }

  .button-lan {
    width: 175px;
    height: 40px;
    background: #005e42;
    color: #fff;
    text-align: center;
    line-height: 40px;
    font-size: clamp(16px, 0.73vw, 24px);
    cursor: pointer;
  }

  .active {
    background-color: #003d2b;
  }

  @media all and (max-width: 992px) {
    .logo {
      width: 140px;
      left: 24px;
      top: 20px;
    }

    .button-lan {
      width: 50%;
    }
  }

  .el-login-footer {
    z-index: 99999;
    background-color: #003d2b;
    height: 50px;
    line-height: 50px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0px;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: Arial;
    font-size: 12px;
  }

  .line {
    margin: 0 5px;
    line-height: 50px;
  }

  ::v-deep {
    .el-carousel__arrow {
      display: none !important;
    }

    .slider-indicator-icon {
      width: 8px !important;
      height: 8px !important;
      margin: 0 4.8px !important;
      background-color: transparent !important;
      border: 1px solid #fff;
    }

    .slider-indicator-active {
      background-color: #fff !important;
    }
  }

  .el-carousel {
    width: 100%;
  }

  .bar span {
    width: 8px !important;
    height: 8px !important;
    margin: 0 4.8px !important;
    border-radius: 50%;
    background-color: transparent !important;
    border: 0.0625rem solid #fff;
  }

  .bar-active {
    background-color: #fff !important;
  }
</style>
