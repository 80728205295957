import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isMobile: null
  },
  getters: {},
  mutations: {
    SET_MOBILE: (state, mobile) => {
      state.isMobile = mobile
    },
  },
  actions: {},
  modules: {}
})
