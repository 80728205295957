import Vue from 'vue'
import App from './App.vue'
import routerpc from './router/index'
// import routermobile from './router/mobile'
import store from './store'
import "@/utils/text.css";

import EasySlider from 'vue-easy-slider'
import './utils/rem'

Vue.use(EasySlider)

const ISMOBILE = function() {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  // stroe.state
  store.commit('SET_MOBILE', flag)
  return flag;
};
Vue.config.productionTip = false

new Vue({
  router: routerpc,
  store,
  render: h => h(App)
}).$mount('#app')
