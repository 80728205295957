<template>
  <div id="app"><router-view /></div>
</template>
<script>
export default {
  //App.vue
  // mounted() {
  //   document.body.addEventListener(
  //     'touchmove',
  //     function(e) {
  //       e.preventDefault(); // 阻止默认的处理方式(阻止下拉滑动的效果)
  //     },
  //     { passive: false }
  //   ); // passive 参数不能省略，用来兼容ios和android
  // }
};
</script>

<style>
/* #app {
  height: 100%;
  width: 100%;
} */
body {
  margin: 0;
  height: 100%;
}
/*
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
