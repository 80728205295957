import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: () => import( /* webpackChunkName: "about" */ '../views/home.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import( /* webpackChunkName: "about" */ '../views/newPrivacy.vue')
  }
  //   {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // }, {
  //   path: '/privacy',
  //   name: 'privacy',
  //   component: () => import( /* webpackChunkName: "about" */ '../views/privacy.vue')
  // },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
